import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';

import { ContrastTo } from '../enums';
import { isDefinedInPixels } from '../helpers';

const getBulletPadding = (sizeInPixels): string => {
  const sizeUnitless = 1 * sizeInPixels.replace(/px/g, '');

  // NB! Assumes size is defined in pixels
  if (sizeUnitless <= 24) {
    return '2px';
  }
  if (sizeUnitless <= 32) {
    return '3px';
  }
  return '4px';
};

const sizeStyles = ({ theme, size }): Record<string, unknown> => {
  const sizeInPixels = theme.sizes[size] || size;
  if (!isDefinedInPixels(sizeInPixels)) {
    throw new Error(
      `Toggle: ${size} is not a valid 'size' prop. Size must be defined in either pixels or Rainbows size scale.`,
    );
  }

  const bulletPadding = getBulletPadding(sizeInPixels);

  return {
    height: sizeInPixels,
    width: `calc((${sizeInPixels} - ${bulletPadding}) * 2)`,
    padding: bulletPadding,

    ':after': {
      width: `calc(${sizeInPixels} - ${bulletPadding} * 2)`,
    },

    '.hidden-checkbox:checked + &:after': {
      left: `calc(${sizeInPixels} - ${bulletPadding} * 2)`,
    },
  };
};

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      backgroundColor: theme.colors.lilac_30,
    };
  }

  return {
    backgroundColor: theme.colors.lilac_25,
  };
};

const disabledStyles = ({ disabled }): Record<string, unknown> => {
  if (!disabled) {
    return {};
  }

  return {
    cursor: 'not-allowed',
    opacity: 0.75,

    ':after': {
      boxShadow: 'none',
      opacity: 0.75,
    },
  };
};

const disabledContrastToStyles = ({
  theme,
  contrastTo,
  disabled,
}): Record<string, unknown> => {
  if (!disabled) {
    return {};
  }

  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      backgroundColor: theme.colors.grey_40,
    };
  }

  return {
    backgroundColor: theme.colors.grey_10,
  };
};

const StyledToggle = styled.label(
  ({ theme }): Record<string, unknown> => ({
    outline: '0',
    display: 'block',
    borderRadius: theme.radii.pill,
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all .4s ease',
    margin: 0,

    '.hidden-checkbox:checked + &': {
      backgroundColor: theme.colors.eGreen,
    },

    ':after': {
      content: '" "',
      position: 'relative',
      display: 'block',
      height: '100%',
      left: '0',
      borderRadius: '50%',
      backgroundColor: theme.colors.lilac_1,
      boxShadow:
        '0 2px 4px 0 rgba(13, 13, 36, 0.12), 0 3px 6px 0 rgba(13, 18, 28, 0.15)',
      transition: 'all .2s ease',
    },
  }),

  // Rainbow prop groups
  everything,

  // Styles affected by props
  sizeStyles,
  contrastToStyles,
  disabledStyles,

  // Styles affected by combinations of props
  disabledContrastToStyles,
);

StyledToggle.defaultProps = {
  theme: t,
  size: '6',
};

export default StyledToggle;

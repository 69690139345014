import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';

const HiddenCheckbox = styled.input(everything);

HiddenCheckbox.defaultProps = {
  theme: t,
  display: 'none',
  type: 'checkbox',
};

export default HiddenCheckbox;

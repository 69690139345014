export enum Layout {
  Left = 'left',
  Right = 'right',
  Vertical = 'vertical',
}

export const getLayoutStyles = (
  layout: Layout,
  theme,
): Record<string, string> => {
  const { space } = theme;
  switch (layout) {
    case Layout.Vertical:
      return {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        innerMargin: `${space[2]} 0 0 0`,
      };
    case Layout.Right:
      return {
        flexDirection: 'row',
        justifyContent: 'space-between',
        innerMargin: `0 0 0 ${space[4]}`,
      };
    case Layout.Left:
    default:
      return {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        innerMargin: `0 ${space[4]} 0 0`,
        innerOrder: '-1',
      };
  }
};

export const getLabelMarginTop = (sizeInPixels: string): string => {
  const sizeUnitless = 1 * sizeInPixels.replace(/px/g, '');

  if (sizeUnitless <= 16) {
    return '0';
  }
  if (sizeUnitless <= 24) {
    return '2px';
  }

  return '6px';
};

import * as React from 'react';

import { Box } from '../../index';

export interface PrimaryTextProps {
  children: React.ReactNode;
}

const PrimaryText = ({ color, ...rest }: PrimaryTextProps): React.ReactNode => {
  return (
    <Box
      borderRadius="circle"
      width="14px"
      height="14px"
      bg={color}
      {...rest}
    />
  );
};

export default PrimaryText;

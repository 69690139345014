import * as React from 'react';

import { Box } from '../../../index';

import { ContrastTo } from '../enums';

import HiddenCheckbox from './HiddenCheckbox';
import StyledToggle from './StyledToggle';

export interface ToggleProps {
  id: string;
  name?: string;
  checked?: boolean;
  onChange?: Function;
  value?: string;
  disabled?: boolean;
  contrastTo?: ContrastTo;
  size?: string;
  ariaDescribedBy?: string;
}

const Toggle = ({
  id,
  name,
  checked,
  onChange,
  value,
  disabled,
  contrastTo,
  size,
  ariaDescribedBy,
  ...rest
}: ToggleProps): React.FunctionComponent => (
  <Box {...rest}>
    <HiddenCheckbox
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
      disabled={disabled}
      aria-describedby={ariaDescribedBy}
      className="hidden-checkbox"
    />
    <StyledToggle
      htmlFor={id}
      contrastTo={contrastTo}
      disabled={disabled}
      checked={checked}
      size={size}
    />
  </Box>
);

export default Toggle;

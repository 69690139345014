import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import t from '../../theme';
import everything from '../../propGroups/everything';
import get from '../../helpers/get';

import LinkIcon from './LinkIcon';

const asButtonStyles = ({ theme }): SerializedStyles => css`
  font-family: ${theme.fonts.sansSerif};
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights.l};
  letter-spacing: ${theme.letterSpacings.s};
  outline: 0;
  background-color: transparent;
  cursor: pointer;
`;

const RainbowLink = styled.a`
  margin: 0;
  padding: 0;
  color: ${get('colors.digital')};
  font-weight: 500;
  text-decoration: none;
  border: 0;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-bottom-width: ${({ fontSize }): string =>
    fontSize && fontSize > 5 ? '2px' : '1px'};
  transition: all 0.1s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  :hover,
  :focus {
    color: ${({ theme, color }): string =>
      (!!color && theme.colors[color]) || color || theme.colors.digital};
    border-bottom-color: ${({ theme, color }): string =>
      (!!color && theme.colors[color]) || color || theme.colors.digital};
  }

  ${({ as }): SerializedStyles => as === 'button' && asButtonStyles};

  ${everything}
`;

RainbowLink.defaultProps = {
  theme: t,
};

interface Props {
  children: React$Node;
  target?: string;
  icon?: React$Node;
}

const Link = ({
  children,
  target = undefined,
  icon = undefined,
  ...rest
}: Props): React.FunctionComponent => (
  <RainbowLink
    target={target}
    rel={target === '_blank' ? 'noopener' : undefined}
    {...rest}>
    {children}
    <LinkIcon icon={icon} target={target} />
  </RainbowLink>
);

export default Link;

import * as React from 'react';

import {
  Box,
  Input,
  Label,
  LabelDescription,
  ErrorMessage,
} from '../../../index';
import { State, ContrastTo } from '../enums';
import { InputProps } from '../Input/Input';

import { getAriaDescribedBy } from '../helpers';

interface InputFieldProps {
  id: string;
  label: string | React.ReactNode;
  name?: string;
  onChange?: Function;
  errorMessage?: string | React.ReactNode;
  labelDescription?: string | React.ReactNode;
  contrastTo?: ContrastTo;
  validationState?: State;
  width?: string;
  maxLength?: number;
  pattern?: string;
  inputMode?: string;
}

const InputField = React.forwardRef(
  (
    {
      // Props needed by InputField
      id,
      label,
      labelDescription,
      errorMessage,
      validationState,
      contrastTo,
      width = '100%',
      // Props passed directly to Input
      icon,
      type,
      onChange,
      name,
      value,
      disabled,
      placeholder,
      maxLength,
      pattern,
      inputMode,
      required,
      ...rest
    }: InputProps & InputFieldProps,
    ref: React.ElementRef<HTMLInputElement>,
  ): React.FunctionComponent => {
    if (!id) {
      throw new Error(`InputField missing required prop 'id'`);
    }
    if (!label) {
      throw new Error(`InputField missing required prop 'label'`);
    }

    const errorMessageId =
      errorMessage && validationState === State.Error
        ? `${id}-error`
        : undefined;
    const labelDescriptionId = labelDescription
      ? `${id}-description`
      : undefined;
    const ariaDescribedBy = getAriaDescribedBy([
      errorMessageId,
      labelDescriptionId,
    ]);

    return (
      <Box width={width} {...rest}>
        <Label htmlFor={id} contrastTo={contrastTo}>
          {label}
        </Label>

        {!!labelDescription && (
          <LabelDescription
            id={labelDescriptionId}
            contrastTo={contrastTo}
            mb="1">
            {labelDescription}
          </LabelDescription>
        )}

        <Input
          contrastTo={contrastTo}
          ariaDescribedBy={ariaDescribedBy}
          mt="1"
          width="100%"
          id={id}
          validationState={validationState}
          icon={icon}
          type={type}
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          inputMode={inputMode}
          required={required}
          ref={ref}
        />

        {!!errorMessage && validationState === State.Error && (
          <ErrorMessage mt="2" contrastTo={contrastTo} id={errorMessageId}>
            {errorMessage}
          </ErrorMessage>
        )}
      </Box>
    );
  },
);

InputField.displayName = 'InputField';
export default InputField;

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import t from '../../theme';
import everything from '../../propGroups/everything';
import get from '../../helpers/get';

import { Layout, Variant, Shape } from './enums';

const peach = ({ theme }) => css`
  color: white;
  background: ${theme.colors.peach_50};
  background-image: ${theme.gradients.gradientPeach};

  &:hover {
    background-image: initial;
    color: white;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.colors.peach_20},
      0 0 4px ${theme.colors.peach_20};
  }

  &:active {
    background: ${theme.colors.peach_60};
  }
`;

const blue = ({ theme }) => css`
  color: white;
  background: ${theme.colors.blue_50};
  background-image: ${theme.gradients.gradientBlue};

  &:hover {
    background-image: initial;
    color: white;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.colors.digitalite},
      0 0 4px ${theme.colors.digitalite};
  }

  &:active {
    background: ${theme.colors.blue_60};
  }
`;

const secondary = ({ theme }) => css`
  color: ${theme.colors.blue_60};
  background: ${theme.colors.blue_5};
  background-image: ${theme.gradients.gradientBlueLight};

  &:hover {
    background-image: initial;
    color: ${theme.colors.blue_60};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.colors.digitalite},
      0 0 4px ${theme.colors.digitalite};
  }

  &:active {
    background: ${theme.colors.blue_10};
  }
`;

const tertiary = ({ theme }) => css`
  color: ${theme.colors.blue_60};
  background: transparent;

  &:focus {
    box-shadow: 0 0 0 2px ${theme.colors.digitalite},
      0 0 4px ${theme.colors.digitalite};
    color: ${theme.colors.blue_60};
  }

  &:active {
    color: ${theme.colors.blue_70};
  }
`;

const primaryDisabled = ({ theme }) => css`
  background: ${theme.colors.grey_10};
  background-image: initial;
  cursor: not-allowed;

  &:hover,
  &:active {
    background: ${theme.colors.grey_10};
    transform: none;
  }
`;

const secondaryDisabled = ({ theme }) => css`
  color: ${theme.colors.grey_30};
  background: ${theme.colors.grey_1};
  background-image: initial;
  cursor: not-allowed;

  &:hover,
  &:active {
    color: ${theme.colors.grey_30};
    background: ${theme.colors.grey_1};
    transform: none;
  }
`;

const tertiaryDisabled = ({ theme }) => css`
  color: ${theme.colors.grey_30};
  cursor: not-allowed;

  &:hover,
  &:active {
    color: ${theme.colors.grey_30};
    transform: none;
  }
`;

const layoutSpaceBetween = () => css`
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const layoutLeft = () => css`
  justify-content: flex-start;
`;
const layoutCenter = () => css`
  justify-content: center;
`;

const shapeCircle = ({ theme }) => css`
  min-width: ${theme.space[7]};
  width: ${theme.space[7]};
  height: ${theme.space[7]};
  justify-content: center;
`;

const StyledButton = styled.button`
  border: 0;
  border-radius: ${get('radii.pill')};
  min-width: 160px;
  min-height: ${get('space.7')};
  padding: ${get('space.3')} ${get('space.5')};
  cursor: pointer;
  font-family: ${get('fonts.sansSerif')};
  font-size: ${get('fontSizes.3')};
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: padding 0.1s, background-image 0.1s, background 0.1s,
    background-color 0.1s, color 0.1s;
  letter-spacing: ${get('letterSpacings.s')};
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    outline: none;
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  /* Variants */
  ${({ variant }) => variant === Variant.Peach && peach};
  ${({ variant }) => variant === Variant.Blue && blue};
  ${({ variant }) => variant === Variant.Secondary && secondary};
  ${({ variant }) => variant === Variant.Tertiary && tertiary};

  /* Layout */
  ${({ layout }) => layout === Layout.SpaceBetween && layoutSpaceBetween};
  ${({ layout }) => layout === Layout.Center && layoutCenter};
  ${({ layout }) => layout === Layout.Left && layoutLeft};

  /* Shape */
  ${({ shape }) => shape === Shape.Circle && shapeCircle};

  /* Disabled */
  ${({ disabled, variant, isLoading }) =>
    disabled && !isLoading && variant === Variant.Blue && primaryDisabled};
  ${({ variant, disabled, isLoading }) =>
    disabled && !isLoading && variant === Variant.Peach && primaryDisabled};
  ${({ variant, disabled, isLoading }) =>
    disabled &&
    !isLoading &&
    variant === Variant.Secondary &&
    secondaryDisabled};
  ${({ variant, disabled, isLoading }) =>
    disabled && !isLoading && variant === Variant.Tertiary && tertiaryDisabled};

  ${everything}
`;

StyledButton.defaultProps = {
  theme: t,
  variant: 'blue',
};

export default StyledButton;

import styled from '@emotion/styled';

import theme from '../../theme';
import everything from '../../propGroups/everything';

const BodyXS = styled.p`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${everything}
`;

BodyXS.defaultProps = {
  theme,
  fontSize: 1,
  color: 'text_muted',
  fontWeight: 'regular',
  lineHeight: 'm',
  letterSpacing: 's',
};

export default BodyXS;

import { get as getKey } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import theme from '../theme';

/*
get(themeValue) can be used to get values from the theme.

EXAMPLE:
  const text = styled.p` {
    margin-bottom: ${get('space.3')};
  }
*/
export default (key: string): string => themeGet(key, getKey(theme, key));

import { border, compose } from 'styled-system';

import outline from '../customProps/outline';
import outlineColor from '../customProps/outlineColor';
import outlineStyle from '../customProps/outlineStyle';
import outlineWidth from '../customProps/outlineWidth';

export default compose(
  border,
  outline,
  outlineColor,
  outlineStyle,
  outlineWidth,
);

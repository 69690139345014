import * as React from 'react';

import { Box } from '../../index';

import Spinner from './Spinner';
import { Layout, Shape } from './enums';

function renderIconOrSpinner({ icon, loading }) {
  if (loading) {
    return <Spinner />;
  }

  if (icon) {
    return icon;
  }

  return null;
}

const getLayoutStyles = ({ icon, layout, shape }) => {
  if (shape === Shape.Circle) {
    return {};
  }
  if (!icon && layout === Layout.Center) {
    return { position: 'absolute' };
  }
  if (!icon) {
    return {};
  }
  if (layout === Layout.Center) {
    return {
      mr: '3',
    };
  }
  if (layout === Layout.SpaceBetween) {
    return {
      ml: '2',
      position: 'relative',
      right: '_2',
    };
  }
  if (layout === Layout.Left) {
    return {
      mr: '2',
      position: 'relative',
      left: '_2',
    };
  }

  return {};
};

interface Props {
  loading: boolean;
  icon?: React.ReactNode;
  layout: Layout;
  shape: Shape;
}

const IconWrap = ({ icon, loading, layout, shape }: Props): React.ReactNode => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontSize={loading ? '1.6em' : `calc(1.6em - 3px * 2)`} // Normal icons look weird if they are as big as the Spinner
    px={!loading && '3px'} // Ensure that the button always remain same width even if fontSize changes
    {...getLayoutStyles({ icon, layout, shape })}>
    {renderIconOrSpinner({ icon, loading })}
  </Box>
);

IconWrap.defaultProps = {
  icon: undefined,
};

export default IconWrap;

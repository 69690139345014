import theme from '../theme';

/*
Create media queries using the breakpoints defined in the theme.

EXAMPLE 1: STRING STYLE
const Testing = styled.div`
  background-color: red;
  ${mediaQuery('l')} { background-color: blue; }
`;

EXAMPLE 2: OBJECT STYLE
const Testing = styled.div({
  backgroundColor: 'red',
  [mediaQuery('l')]: { backgroundColor: 'blue' },
});
 */
export default (bp: string): string =>
  `@media (min-width: ${theme.breakpoints[bp]})`;

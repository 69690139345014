import * as React from 'react';

import { Box } from '../../index';

import { Layout, Shape } from './enums';

const getShapeStyles = ({ shape }) => {
  // If Circle, visually hide the content while
  // still allowing screen readers to see the content
  if (shape === Shape.Circle) {
    return {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(100%)',
      height: '1px',
      width: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
    };
  }
  return {};
};

interface Props {
  children: React.ReactNode;
  layout?: Layout;
  shape: Shape;
  icon?: React.ReactNode;
  loading: boolean;
}

const ContentWrap = ({
  children,
  shape,
  icon,
  layout,
  loading,
}: Props): React.ReactNode => {
  // We hide the text using "opacity: 0" in the center layout
  // to ensure that the button keeps the same width when in
  // the loading state.
  const hideText = loading && !icon && layout === Layout.Center;

  return (
    <Box
      display="inline"
      opacity={hideText ? 0 : 1}
      {...getShapeStyles({ shape })}>
      {children}
    </Box>
  );
};

ContentWrap.defaultProps = {
  icon: undefined,
  layout: undefined,
};

export default ContentWrap;

import * as React from 'react';
import styled from '@emotion/styled';

import { theme as t, Box, everything } from '../../../index';
import { State, ContrastTo } from '../enums';

import InputIcon from './InputIcon';

const iconStyles = ({ theme, icon }): Record<string, unknown> => {
  if (!icon) {
    return {};
  }

  return {
    paddingRight: theme.space[7],
  };
};

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      ':enabled:hover, :focus': {
        boxShadow: `0px 0px 0px 2px ${theme.colors.digitalite}`,
      },
    };
  }

  return {
    ':enabled:hover, :focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.digital}`,
    },
  };
};

const errorStyles = ({ theme, validationState }): Record<string, unknown> => {
  return validationState === State.Error
    ? {
        boxShadow: `0 2px 0 ${theme.colors.scarlet},
                    0px 0px 0px 1px ${theme.colors.lilac_25}`,

        ':enabled:hover': {
          boxShadow: `0 0 0 2px ${theme.colors.scarlet}`,
        },
      }
    : {};
};

const errorAndContrastToStyles = ({
  theme,
  contrastTo,
  validationState,
}): Record<string, unknown> => {
  if (validationState !== State.Error) {
    return {};
  }

  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      ':focus': {
        boxShadow: `0px 0px 0px 2px ${theme.colors.digitalite} !important`,
      },
    };
  }

  return {
    ':focus': {
      boxShadow: `0px 0px 0px 2px ${theme.colors.digital} !important`,
    },
  };
};

const disabledAndContrastToStyles = ({
  theme,
  contrastTo,
  disabled,
}): Record<string, unknown> => {
  if (!disabled) {
    return {};
  }

  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      ':disabled': {
        color: theme.colors.grey_20,
        backgroundColor: theme.colors.grey_40,
      },
    };
  }

  return {
    ':disabled': {
      color: theme.colors.grey_40,
      backgroundColor: theme.colors.grey_10,
    },
  };
};

const RainbowInput = styled.input(
  ({ theme }): Record<string, unknown> => ({
    fontSize: theme.fontSizes[3],
    fontFamily: theme.fonts.sansSerif,
    height: `calc(${theme.sizes[7]} - 2px)`, // -2px to compensate for boxShadow
    width: '100%',
    padding: `${theme.space[3]} ${theme.space[5]}`,
    outline: 'none',
    border: 'none',
    boxShadow: `0px 0px 0px 1px ${theme.colors.lilac_25}`,
    borderRadius: theme.radii[1],
    color: theme.colors.text_body,
    transition: 'all 0.2s ease-out',
    letterSpacing: theme.letterSpacings.s,
    appearance: 'none',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',

    '::placeholder, :placeholder': {
      color: theme.colors.grey_30,
      opacity: 1, // Firefox fix
    },
    ':focus': {
      borderRadius: `calc(${theme.sizes[7]} / 2)`,
    },

    ':disabled': {
      boxShadow: 'none',
      cursor: 'not-allowed',
    },
  }),

  // Styles affected by props
  contrastToStyles,
  errorStyles,
  iconStyles,

  // Styles affected by combinations of props
  errorAndContrastToStyles,
  disabledAndContrastToStyles,

  // Rainbow prop groups
  everything,
);

RainbowInput.defaultProps = {
  theme: t,
  type: 'text',
};

export interface InputProps {
  ariaDescribedBy?: string;
  contrastTo?: ContrastTo;
  disabled?: boolean;
  icon?: React.ReactNode;
  id?: string;
  maxLength?: number | string;
  name?: string;
  onChange?: Function;
  placeholder?: string;
  type?: 'text' | 'password' | 'tel' | 'email';
  validationState?: State;
  value?: string;
  width?: string;
  pattern?: string;
  inputMode?: string;
  required?: boolean;
}

const Input = React.forwardRef(
  (
    {
      ariaDescribedBy,
      contrastTo,
      disabled,
      icon,
      id,
      inputMode,
      maxLength,
      name,
      onChange,
      pattern,
      placeholder,
      required,
      type,
      validationState,
      value,
      width,
      ...rest
    }: InputProps,
    ref: React.ElementRef<HTMLInputElement>,
  ): React.FunctionComponent => {
    const allowedTypes = ['text', 'password', 'tel', 'email'];

    if (type && !allowedTypes.includes(type)) {
      throw new Error(`<Input type="${type}"/> is not supported`);
    }

    return (
      <Box width={width || '12'} position="relative" {...rest}>
        <RainbowInput
          id={id}
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          icon={icon}
          validationState={validationState}
          aria-invalid={validationState === State.Error ? 'true' : undefined}
          aria-describedby={ariaDescribedBy}
          contrastTo={contrastTo}
          pattern={pattern}
          inputMode={inputMode}
          required={required}
          ref={ref}
        />
        {!!icon && <InputIcon>{icon}</InputIcon>}
      </Box>
    );
  },
);

Input.displayName = 'Input';
export default Input;

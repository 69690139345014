import * as React from 'react';

import { Box, theme } from '../../../index';

interface Props {
  icon: React$Node;
}

const InputIcon = ({ children }: Props): React.FunctionComponent => (
  <Box
    position="absolute"
    top="50%"
    marginTop="-0.5em"
    right="3"
    fontSize={theme.space[5]}>
    {children}
  </Box>
);

export default InputIcon;

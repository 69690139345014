import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';
import { ContrastTo } from '../enums';

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return { color: theme.colors.lilac_40 };
  }
  return { color: theme.colors.text_muted };
};

const LabelDescription = styled.p(
  {
    margin: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  contrastToStyles,
  everything,
);

LabelDescription.defaultProps = {
  theme: t,
  display: 'block',
  fontSize: 2,
  fontWeight: 'regular',
  lineHeight: 'm',
  letterSpacing: 's',
  maxWidth: 'measure',
};

export default LabelDescription;

import * as React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { Box } from '../../index';

const spinnerFade = keyframes({
  '0%': {
    opacity: '1',
  },
  '100%': {
    opacity: '0.1',
  },
});

const Beam = styled.line(({ animationDelay }) => ({
  opacity: '0.1',
  animation: spinnerFade,
  animationDuration: '1s',
  animationIterationCount: 'infinite',
  animationDelay: animationDelay || '0s',
}));

const Spinner = (): React.ReactNode => {
  return (
    <Box lineHeight="0.5" my="-6px">
      <svg
        width="1em"
        height="1em"
        viewBox="-1 -1 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round">
        <Beam x1="43.6139" y1="41.0506" x2="32.1232" y2="31.4088" />
        <Beam animationDelay="0.13s" x1="25.5" y1="49" x2="25.5" y2="34" />
        <Beam
          animationDelay="0.26s"
          x1="1"
          y1="-1"
          x2="16"
          y2="-1"
          transform="matrix(0.766044 -0.642788 -0.642788 -0.766044 5 40.9274)"
        />
        <Beam animationDelay="0.39s" x1="1" y1="26" x2="16" y2="26" />
        <Beam
          animationDelay="0.52s"
          x1="6.40883"
          y1="9.87674"
          x2="17.8995"
          y2="19.5186"
        />
        <Beam animationDelay="0.65s" x1="25.5" y1="16" x2="25.5" y2="1" />
        <Beam
          animationDelay="0.78s"
          x1="1"
          y1="-1"
          x2="16"
          y2="-1"
          transform="matrix(-0.766044 0.642788 0.642788 0.766044 45.0227 10)"
        />
        <Beam animationDelay="0.91s" x1="34" y1="26" x2="49" y2="26" />
      </svg>
    </Box>
  );
};

export default Spinner;

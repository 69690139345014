import * as React from 'react';

import StyledStackedButton from './StyledStackedButton';
import PrimaryText from './PrimaryText';
import SecondaryText from './SecondaryText';
import Dot from './Dot';

interface StackedButtonProps {
  onClick?: Function;
  selected?: boolean;
  children: React.ReactNode;
}

const StackedButton = ({
  onClick,
  selected,
  children,
  ...rest
}: StackedButtonProps): React.ReactNode => {
  return (
    <StyledStackedButton selected={selected} onClick={onClick} {...rest}>
      {children}
    </StyledStackedButton>
  );
};

StackedButton.PrimaryText = PrimaryText;
StackedButton.SecondaryText = SecondaryText;
StackedButton.Dot = Dot;

export default StackedButton;

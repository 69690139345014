import * as React from 'react';

import StyledButton from './StyledButton';
import IconWrap from './IconWrap';
import ContentWrap from './ContentWrap';
import { Layout, Variant, Shape } from './enums';

const getDefaultLayout = (icon) => (icon ? Layout.SpaceBetween : Layout.Center);

const isDisabled = (disabled, loading) => {
  // Make button disabled when loading unless explicitly enabled
  if (loading && disabled === undefined) {
    return true;
  }
  return disabled;
};

interface Props {
  children: React.ReactNode;
  variant?: Variant;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  layout?: Layout;
  shape?: Shape;
  onClick?: (e) => void;
}

const Button = ({
  children,
  loading,
  variant,
  layout: rawLayout,
  disabled: rawDisabled,
  onClick,
  icon,
  shape,
  ...rest // disabled, as, width, etc
}: Props): React.ReactNode => {
  const layout = rawLayout || getDefaultLayout(icon);
  const disabled = isDisabled(rawDisabled, loading);

  return (
    <StyledButton
      variant={variant}
      layout={layout}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      isLoading={loading}
      shape={shape}
      {...rest}>
      <IconWrap icon={icon} layout={layout} loading={loading} shape={shape} />
      <ContentWrap icon={icon} layout={layout} loading={loading} shape={shape}>
        {children}
      </ContentWrap>
    </StyledButton>
  );
};

Button.defaultProps = {
  layout: undefined,
  icon: undefined,
  loading: false,
  disabled: undefined,
  onClick: undefined,
  variant: Variant.Blue,
  shape: Shape.Pill,
};

export default Button;

import styled from '@emotion/styled';

import theme from '../../theme';
import everything from '../../propGroups/everything';

const BodyL = styled.p`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${everything}
`;

BodyL.defaultProps = {
  theme,
  fontSize: 4,
  color: 'text_body',
  fontWeight: 'regular',
  lineHeight: 'l',
  letterSpacing: 's',
};

export default BodyL;

import styled from '@emotion/styled';

import theme from '../../theme';
import everything from '../../propGroups/everything';

const Box = styled.div(
  {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  everything,
);

Box.defaultProps = {
  theme,
};

export default Box;

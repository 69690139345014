import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { theme as t, everything } from '../../index';

const pulseOnce = keyframes({
  '0%': {
    transform: 'scale3d(1, 1, 1)',
  },
  '50%': {
    transform: 'scale3d(1.02, 1.02, 1)',
  },
  '100%': {
    transform: 'scale3d(1, 1, 1)',
  },
});

const selectedStyles = ({ theme, selected }): Record<string, unknown> => {
  return selected
    ? {
        borderColor: theme.colors.royalBlue,
        animation: `${pulseOnce} 0.4s ease`,
      }
    : { borderColor: theme.colors.lilac_25 };
};

const StyledStackedButton = styled.button(
  ({ theme }): Record<string, unknown> => ({
    appearance: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    border: theme.borders[2],
    borderRadius: theme.radii.pill,
    backgroundColor: 'white',
    width: '100%',
    minHeight: theme.space[7],
    padding: `${theme.space[3]} ${theme.space[4]}`,
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'all 0.4s',
    textAlign: 'left',
    fontFamily: 'inherit',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.space[4],
    transform: 'scale3d(1, 1, 1)',

    ':focus': {
      outline: 'none',
    },

    ':last-child': {
      marginBottom: 0,
    },
  }),

  // Rainbow prop groups
  everything,

  // Styles affected by props
  selectedStyles,
);

StyledStackedButton.defaultProps = {
  theme: t,
};

export default StyledStackedButton;

import * as React from 'react';
import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';

const InnerForm = styled.form(everything);

InnerForm.defaultProps = {
  theme: t,
};

const injectContrastToIntoChildren = (
  children,
  contrastTo,
): React.ReactNode[] => {
  const childrenArr = React.Children.toArray(children);
  return childrenArr.map(
    (element): React.ReactNode =>
      element.props.contrastTo
        ? element
        : React.cloneElement(element, { contrastTo }),
  );
};

const Form = ({
  children,
  contrastTo,
  ...rest
}: Props): React.FunctionComponent => {
  let elements = children;

  if (contrastTo) {
    elements = injectContrastToIntoChildren(elements, contrastTo);
  }

  return <InnerForm {...rest}>{elements}</InnerForm>;
};

export default Form;

import { compose, grid } from 'styled-system';

import gridColumnStart from '../customProps/gridColumnStart';
import gridColumnEnd from '../customProps/gridColumnEnd';
import gridRowStart from '../customProps/gridRowStart';
import gridRowEnd from '../customProps/gridRowEnd';

export default compose(
  grid,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
);

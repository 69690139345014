import * as React from 'react';

import { BodyS } from '../../index';

export interface PrimaryTextProps {
  children: React.ReactNode;
}

const PrimaryText = ({
  children,
  ...rest
}: PrimaryTextProps): React.ReactNode => {
  return (
    <BodyS ml="auto" mr="2" {...rest}>
      {children}
    </BodyS>
  );
};

export default PrimaryText;

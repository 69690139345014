import styled from '@emotion/styled';

import theme from '../../theme';
import everything from '../../propGroups/everything';

const Text = styled.p`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${everything}
`;

Text.defaultProps = {
  theme,
  fontSize: 3,
  color: 'text_body',
  fontWeight: 'regular',
  lineHeight: 'l',
  letterSpacing: 's',
};

export default Text;

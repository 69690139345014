import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';
import { ContrastTo } from '../enums';

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return { color: theme.colors.red_40 };
  }
  return { color: theme.colors.scarlet };
};

const ErrorMessage = styled.p(
  {
    margin: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  contrastToStyles,
  everything,
);

ErrorMessage.defaultProps = {
  theme: t,
  fontSize: 2,
  fontWeight: 'regular',
  fontStyle: 'italic',
  lineHeight: 'm',
  letterSpacing: 's',
  maxWidth: 'measure',
  display: 'block',
  role: 'alert',
};

export default ErrorMessage;

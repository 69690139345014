import { compose } from 'styled-system';

import background from './background';
import border from './border';
import color from './color';
import flexbox from './flexbox';
import grid from './grid';
import layout from './layout';
import misc from './misc';
import position from './position';
import space from './space';
import shadow from './shadow';
import typography from './typography';

export default compose(
  background,
  border,
  color,
  flexbox,
  grid,
  misc,
  layout,
  position,
  space,
  shadow,
  typography,
);

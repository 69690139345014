import styled from '@emotion/styled';

import theme from '../../theme';
import everything from '../../propGroups/everything';

const CaptionXS = styled.p`
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${everything}
`;

CaptionXS.defaultProps = {
  theme,
  fontSize: 1,
  color: 'text_muted',
  fontWeight: 'regular',
  lineHeight: 's',
  letterSpacing: 'l',
  textTransform: 'uppercase',
};

export default CaptionXS;

import { compose } from 'styled-system';

import animationDelay from '../customProps/animationDelay';
import clip from '../customProps/clip';
import clipPath from '../customProps/clipPath';
import cursor from '../customProps/cursor';
import listStylePosition from '../customProps/listStylePosition';
import listStyleType from '../customProps/listStyleType';
import objectFit from '../customProps/objectFit';
import objectPosition from '../customProps/objectPosition';
import overflowWrap from '../customProps/overflowWrap';
import shapeOutside from '../customProps/shapeOutside';
import transition from '../customProps/transition';

export default compose(
  animationDelay,
  clip,
  clipPath,
  cursor,
  listStylePosition,
  listStyleType,
  objectFit,
  objectPosition,
  overflowWrap,
  shapeOutside,
  transition,
);

import * as React from 'react';

import { Box } from '../../index';

export interface Props {
  children: React.ReactNode;
}

const Badge = ({ children, ...rest }: Props): React.ReactNode => {
  return (
    <Box
      as="span"
      borderRadius="2"
      backgroundImage="gradientPeach"
      color="white"
      fontWeight="semibold"
      px="1em"
      py="0.25em"
      fontSize="0.75em"
      position="relative"
      bottom="0.09em"
      {...rest}>
      {children}
    </Box>
  );
};

export default Badge;

import * as React from 'react';

import { Box } from '../../index';

import IconNewTab from './IconNewTab';

interface Props {
  icon: React$Node;
  target: React$Node;
}

const LinkIcon = ({ icon, target }: Props): React.FunctionComponent => {
  if (icon) {
    return (
      <Box
        as="span"
        display="inline-block"
        transform="translateY(.1875em)"
        ml=".25em">
        {icon}
      </Box>
    );
  }

  if (target === '_blank' && icon !== null) {
    return (
      <Box
        as="span"
        display="inline-block"
        transform="translateY(.125em)"
        ml=".25em">
        <IconNewTab />
      </Box>
    );
  }
  return null;
};

export default LinkIcon;

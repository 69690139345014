// Takes an array of id's and returns them as a space separated string.
// If all params are undefined, it will return undefined instead.
// Examples
// ['hey', 'ho', 'lets', 'go'] => 'hey ho lets go'
// ['hey', undefined, 'lets', undefined] => 'hey lets'
// [undefined, undefined] => undefined
export const getAriaDescribedBy = (ids: string | undefined): string | void => {
  const result = ids.filter((elem) => elem).join(' ');
  return result === '' ? undefined : result;
};

// Checks whether a string is defined in pixels or not
// Examples (Input → Output)
// '4px' → true
// '4em' → false
// '4' → false
// '4ppx' → false
// 'px' → false
export const isDefinedInPixels = (str: string): boolean => {
  if (!str.endsWith('px')) {
    return false;
  }
  const unitlessStr = str.replace(/px/g, '');
  const isCastableToNumber = !!(unitlessStr * 1);
  return isCastableToNumber;
};

import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';
import { ContrastTo } from '../enums';
import { isDefinedInPixels } from '../helpers';

const getCheckmarkWidth = (sizeInPixels): string => {
  const sizeUnitless = 1 * sizeInPixels.replace(/px/g, '');

  if (sizeUnitless <= 16) {
    return '65%';
  }
  if (sizeUnitless <= 24) {
    return '60%';
  }
  return '50%';
};

const sizeStyles = ({ theme, size }): Record<string, unknown> => {
  const sizeInPixels = theme.sizes[size] || size;
  if (!isDefinedInPixels(sizeInPixels)) {
    throw new Error(
      `Checkbox: ${size} is not a valid 'size' prop. Size must be defined in either pixels or Rainbows size scale.`,
    );
  }

  const checkmarkWidth = getCheckmarkWidth(sizeInPixels);

  return {
    height: sizeInPixels,
    width: sizeInPixels,

    ':after': {
      backgroundSize: checkmarkWidth,
    },
  };
};

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      backgroundColor: theme.colors.lilac_30,
    };
  }

  return {
    backgroundColor: theme.colors.lilac_25,
  };
};

const disabledStyles = ({ disabled }): Record<string, unknown> => {
  if (!disabled) {
    return {};
  }

  return {
    cursor: 'not-allowed',
    opacity: 0.75,
  };
};

const disabledContrastToStyles = ({
  theme,
  contrastTo,
  disabled,
}): Record<string, unknown> => {
  if (!disabled) {
    return {};
  }

  if (contrastTo === ContrastTo.DarkBackground) {
    return {
      backgroundColor: theme.colors.grey_40,
    };
  }

  return {
    backgroundColor: theme.colors.grey_10,
  };
};

const StyledCheckbox = styled.label(
  ({ theme }): Record<string, unknown> => ({
    outline: '0',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    margin: 0,
    borderRadius: theme.radii[1],

    '.hidden-checkbox:checked + &': {
      backgroundColor: theme.colors.eGreen,
    },

    ':after': {
      content: '" "',
      position: 'relative',
      display: 'block',
      height: '100%',

      // The background image is an URL encoded checkmark SVG (http://www.asiteaboutnothing.net/c_decode-url.html)
      backgroundImage:
        'url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2016%2014%22%3E%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%23FFF%22%20d%3D%22M5.531%209.712l7.777-8.273a1.181%201.181%200%200%201%201.676.08c.453.49.475%201.267.052%201.784l-8.64%209.193c-.48.5-1.249.5-1.729%200L.964%208.556a1.375%201.375%200%200%201%20.052-1.783%201.181%201.181%200%200%201%201.676-.081l2.84%203.02z%22%2F%3E%3C%2Fsvg%3E)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      opacity: '0',
    },

    '.hidden-checkbox:checked + &:after': {
      opacity: '1',
    },
  }),

  // Rainbow prop groups
  everything,

  // Styles affected by props
  sizeStyles,
  contrastToStyles,
  disabledStyles,

  // Styles affected by combinations of props
  disabledContrastToStyles,
);

StyledCheckbox.defaultProps = {
  theme: t,
  size: '6',
};

export default StyledCheckbox;

import styled from '@emotion/styled';

import { theme as t, everything } from '../../index';

const GradientText = styled.span(everything);

GradientText.defaultProps = {
  theme: t,
  color: 'black',
  backgroundImage: 'gradientSun',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  boxDecorationBreak: 'clone',
  WebkitBoxDecorationBreak: 'clone',
};

export default GradientText;

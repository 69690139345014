import * as React from 'react';

import { Box, Toggle, Label, LabelDescription, theme } from '../../../index';
import { ContrastTo } from '../enums';
import { ToggleProps } from '../Toggle/Toggle';
import { getAriaDescribedBy, isDefinedInPixels } from '../helpers';

import { Layout, getLayoutStyles, getLabelMarginTop } from './helpers';

interface ToggleFieldProps {
  id: string;
  label: string | React.ReactNode;
  labelDescription?: string | React.ReactNode;
  name?: string;
  onChange?: Function;
  contrastTo?: ContrastTo;
  layout?: Layout;
}

const ToggleField = ({
  // Props needed by ToggleField
  id,
  label,
  labelDescription,
  contrastTo,
  layout,
  // Props passed directly to Toggle
  checked,
  onChange,
  name,
  value,
  disabled,
  size,
  ...rest
}: ToggleProps & ToggleFieldProps): React.FunctionComponent => {
  if (!id) {
    throw new Error(`ToggleField missing required prop 'id'`);
  }
  if (!label) {
    throw new Error(`ToggleField missing required prop 'label'`);
  }

  const sizeInPixels = theme.sizes[size] || size;
  if (!isDefinedInPixels(sizeInPixels)) {
    throw new Error(
      `ToggleField: ${size} is not a valid 'size' prop. Size must be defined in either pixels or Rainbows size scale.`,
    );
  }

  const labelMarginTop = getLabelMarginTop(sizeInPixels);
  const {
    flexDirection,
    justifyContent,
    innerMargin,
    innerOrder,
  } = getLayoutStyles(layout, theme);
  const labelDescriptionId = labelDescription ? `${id}-description` : undefined;
  const ariaDescribedBy = getAriaDescribedBy([labelDescriptionId]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      {...rest}>
      <Box mt={labelMarginTop}>
        <Label htmlFor={id} contrastTo={contrastTo}>
          {label}
        </Label>
        {!!labelDescription && (
          <LabelDescription
            id={labelDescriptionId}
            contrastTo={contrastTo}
            mt="1">
            {labelDescription}
          </LabelDescription>
        )}
      </Box>
      <Toggle
        id={id}
        checked={checked}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
        contrastTo={contrastTo}
        ariaDescribedBy={ariaDescribedBy}
        size={size}
        order={innerOrder}
        m={innerMargin}
      />
    </Box>
  );
};

ToggleField.defaultProps = {
  size: '6',
  layout: Layout.Right,
};

export default ToggleField;

import styled from '@emotion/styled';

import { theme as t, everything } from '../../../index';
import { ContrastTo } from '../enums';

const contrastToStyles = ({ theme, contrastTo }): Record<string, unknown> => {
  if (contrastTo === ContrastTo.DarkBackground) {
    return { color: 'white' };
  }
  return { color: theme.colors.text_heading };
};

const Label = styled.label(
  {
    margin: 0,
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  contrastToStyles,
  everything,
);

Label.defaultProps = {
  theme: t,
  fontSize: 3,
  display: 'block',
  fontWeight: 'medium',
  lineHeight: 'm',
  letterSpacing: 's',
};

export default Label;

export enum Layout {
  SpaceBetween = 'space-between',
  Center = 'center',
  Left = 'left',
}

export enum Variant {
  Blue = 'blue',
  Peach = 'peach',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export enum Shape {
  Circle = 'circle',
  Pill = 'pill',
}

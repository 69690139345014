import { compose, background } from 'styled-system';

import backgroundAttachment from '../customProps/backgroundAttachment';
import backgroundClip from '../customProps/backgroundClip';
import backgroundImage from '../customProps/backgroundImage';
import filter from '../customProps/filter';
import webkitBackgroundClip from '../customProps/webkitBackgroundClip';
import boxDecorationBreak from '../customProps/boxDecorationBreak';
import webkitBoxDecorationBreak from '../customProps/webkitBoxDecorationBreak';

export default compose(
  background,
  backgroundAttachment,
  backgroundClip,
  backgroundImage,
  filter,
  webkitBackgroundClip,
  boxDecorationBreak,
  webkitBoxDecorationBreak,
);

import * as React from 'react';

import { H6 } from '../../index';

export interface PrimaryTextProps {
  children: React.ReactNode;
}

const PrimaryText = ({
  children,
  ...rest
}: PrimaryTextProps): React.ReactNode => {
  return (
    <H6 as="p" ml="2" {...rest}>
      {children}
    </H6>
  );
};

export default PrimaryText;
